import React, { Component } from 'react';
import Carousel, { Modal, ModalGateway } from  'react-images';

export default class Lightbox extends Component {
  render() {
    const { images, isOpen, currentImage, onClose } = this.props;
    return (
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={onClose}>
            <Carousel views={images} currentIndex={currentImage} />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}