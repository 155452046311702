import React, { Component, useRef } from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink';
import Layout from '../components/Layout';
import { Breadcrumbs } from '../components/Breadcrumbs';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { Image } from '../components/Image';
import Lightbox from '../components/Lightbox';
import Leads from '../components/Leads';
// import PipedriveForm from '../components/PipedriveForm';
import { ImageAndText } from '../components/Acf/ImageAndText';
import { RelatedProjects } from '../components/Posts/Related/RelatedProjects';
import { decodeEntities } from '../utils/helpers';
import Heading from '../components/Heading/Heading';
import './project.scss';

class ProjectGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      photos: props.gallery.map(photo => Object.assign({ source: photo.source_url?.replace(/\/wp-content\/uploads\//g,`${process.env.GATSBY_WP_ADDRESS}/wp-content/uploads/`), alt: photo.alt_text})),
    };
  }

  openLightbox(photo, event) {
    event.preventDefault();
    this.setState({ lightboxIsOpen: true, currentPhoto: photo });
  }

  closeLightbox() {
    this.setState({ lightboxIsOpen: false });
  }

  render() {
    const { gallery } = this.props;
    const { photos, currentPhoto, lightboxIsOpen } = this.state;

    return (
      <div>
        <div className="project-gallery">
          {gallery.slice(0,7).map((gPhoto, i) => (
            <a href={gPhoto.source_url?.replace(/\/wp-content\/uploads\//g,`${process.env.GATSBY_WP_ADDRESS}/wp-content/uploads/`)} className="gallery-image" onClick={e => this.openLightbox(i, e)} key={i}>
              <Image key={i} src={gPhoto.source_url?.replace(/\/wp-content\/uploads\//g,`${process.env.GATSBY_WP_ADDRESS}/wp-content/uploads/`)} position="relative" alt={gPhoto.alt_text} />
            </a>
          ))}
        </div>
        <Lightbox
          images={photos}
          currentImage={currentPhoto}
          isOpen={lightboxIsOpen}
          onClose={() => this.closeLightbox()}
        />
      </div>
    );
  }
}

export const ProjectPostTemplate = (props) => {
  const { post, relatedProjects, location, siteSettings } = props;
  const galleryComponent = useRef(null);
  const {
    categories,
    title,
    featured_image_url: featureImage,
    acf
  } = post;
  const {
    subTitle,
    vision,
    challenge,
    outcome,
    mentions,
    name,
    testimonial,
    gallery
  } = acf;

  const { options: {
    projectsGlobalContactFormHeading,
    projectsGlobalContactFormIntro,
    enableProjectsStaticBlock,
    projectsGlobalStaticBlockImage,
    projectsGlobalStaticBlockTitle,
    projectsGlobalStaticBlockContent,
    projectsGlobalStaticBlockAction
  } } = siteSettings;
  return (
    <section className="single-project">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <Breadcrumbs location={location} />
            <Heading className="page-title" tag="h1">{title}</Heading>
            {subTitle && <span className="subtitle">{decodeEntities(subTitle)}</span>}
            {featureImage && <Image src={featureImage.source_url} className="featured-image" position="relative" />}
            {(vision || challenge || outcome) && (
              <div className="about-section">
                {vision && (
                  <div className="about-col">
                    <Heading className="about-col-title section-title" tag="h2">The Vision</Heading>
                    <div className="about-col-content" dangerouslySetInnerHTML={{__html: vision}} />
                  </div>
                )}
                {challenge && (
                  <div className="about-col">
                    <Heading className="about-col-title section-title" tag="h2">The Challenge</Heading>
                    <div className="about-col-content" dangerouslySetInnerHTML={{__html: challenge}} />
                  </div>
                )}
                {outcome && (
                  <div className="about-col">
                    <Heading className="about-col-title section-title" tag="h2">The Outcome</Heading>
                    <div className="about-col-content" dangerouslySetInnerHTML={{__html: outcome}} />
                  </div>
                )}
              </div>
            )}
            {testimonial && (
              <div className="testimonial-section">
                <Heading className="section-title center" tag="span">From the Client</Heading>
                <span className="stars" />
                <p className="testimonial">{testimonial}</p>
                <span className="name">{name}</span>
              </div>
            )}
            <div className="project-gallery-section">
              {(gallery && gallery.length > 0) && (
                <>
                  <Heading tag="h2" className="section-title">Project Gallery</Heading>
                  <ProjectGallery gallery={gallery} ref={galleryComponent}  />
                  <button type="button" aria-label="View All Project Images" className="view-all action" onClick={e => galleryComponent.current.openLightbox(0, e)}>View All {gallery.length} Project Images</button>
                  <p className="cta-btn-wrapper">                  
                    <a className="btn" href="#Contact">Enquire about getting a similar design for your&nbsp;home</a>
                  </p>
                </>
              )}
              <div className="other-information">
                {mentions && (
                  <div className="mentions" dangerouslySetInnerHTML={{__html: mentions}} />
                )}
                {(categories && categories.length > 0) && (
                  <div className="categories">
                    <h3>Categories:</h3>
                    <ul>
                      {categories.map((category, index) => (
                        <li key={`${category.slug}cat`}>
                          {category.slug && (
                            <span>
                              {`${category.name}${index < categories.length - 1 ? ', ' : ''}`}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {(relatedProjects.edges && relatedProjects.edges.length > 0) && (
              <RelatedProjects title="Related Projects" pathPrefix="project-gallery" projects={relatedProjects.edges} />
            )}
            {enableProjectsStaticBlock && (
              <ImageAndText
                image={projectsGlobalStaticBlockImage}
                title={projectsGlobalStaticBlockTitle}
                contentBody={projectsGlobalStaticBlockContent}
                actions={[{link: {...projectsGlobalStaticBlockAction}}]}
              />
            )}
            <div id="Contact" className="contact-section">
              {(projectsGlobalContactFormHeading || projectsGlobalContactFormIntro) && (
                <div className="contact-intro">
                  {projectsGlobalContactFormHeading && <Heading tag="h2" className="section-title center">{decodeEntities(projectsGlobalContactFormHeading)}</Heading>}
                  {projectsGlobalContactFormIntro && (
                    <div className="contact-intro-content">
                      <p>{projectsGlobalContactFormIntro}</p>
                    </div>
                  )}
                </div>
              )}
              {/* <PipedriveForm /> */}
              <Leads id={15} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPost = ({ data, location }) => {
  const { wordpressPost: post, allWordpressWpProjects: relatedProjects, wordpressWpSettings, siteSettings } = data;
  if (!post) return null;
  const { title, yoast } = post;
  const canonicalAttr = yoast.canonical ? yoast.canonical : `${wordpressWpSettings.siteUrl}/project-gallery/${data.wordpressPost.slug}/`
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        canonical={canonicalAttr}
      />
      <ProjectPostTemplate
        post={post}
        blogSlug="projects"
        location={location}
        siteSettings={siteSettings}
        relatedProjects={relatedProjects}
      />
    </Layout>
  )
}

export default Previewable(ProjectPost, 'projects');

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    title
  }
  query ProjectPostByID($id: String!, $relatedCategories: [String]) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        projectsGlobalContactFormHeading
        projectsGlobalContactFormIntro
        enableProjectsStaticBlock
        projectsGlobalStaticBlockImage {
          source_url
        }
        projectsGlobalStaticBlockTitle
        projectsGlobalStaticBlockContent
        projectsGlobalStaticBlockAction {
          title
          url
          target
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      slug
      wordpress_id
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      }
      acf {
        subTitle
        vision
        challenge
        outcome
        mentions
        name
        testimonial
        gallery {
          source_url
          alt_text
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        canonical: canonical
      }
    }
    allWordpressWpProjects(
      filter: {
        categories: { elemMatch: { slug: { in: $relatedCategories } } },
        id: { ne: $id }
      },
      limit: 3
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
  }
`
